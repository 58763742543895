<template>
  <div>
    <base-dialog small :show="extendDisplay" @close="extendDisplay = false">
      <div class="mx-auto extend-dialog">
        <img
          class="d-block mx-auto"
          src="../../assets/images/profile/addtime.svg"
          alt=""
        />
        <h4 class="text-big text-line color-primary font-weight-bold">
          <span>ต่อเวลาเรียนฟรี</span>
        </h4>
        <button class="secondary mb-2" @click="extendCourseTime">
          <p class="text-big m-0">ต่อเวลาเรียน 7 วัน</p>
        </button>
      </div>
    </base-dialog>
    <h3 class="font-weight-bold mb-4">คอร์สเรียนของฉัน</h3>
    <div v-if="!isLoading">
      <div v-if="myCourseList.length > 0">
        <div class="row">
          <div
            v-for="course in myCourseList"
            :key="course.name"
            class="col-12 col-md-6 my-2"
          >
            <CourseCard
              :course="course"
              type="myCourse"
              @extend="showExtendTime"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <h3 class="color-text text-center">ไม่มีคอร์สที่จะแสดง</h3>
      </div>
    </div>
    <div v-else class="d-flex justify-content-center">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>

<script>
import axios from "../../api/faAPI";
import moment from "moment";
import CourseCard from "../../components/CourseCard.vue";
import baseUrl from "../../util/backend";
export default {
  components: {
    CourseCard,
  },
  data() {
    return {
      isLoading: false,
      extendDisplay: false,
      extendData: null,
      currTime: null,
      utcDate: null,
      myCourseList: [],
    };
  },
  methods: {
    showExtendTime(data) {
      this.extendData = data;
      this.extendDisplay = true;
    },
    async extendCourseTime() {
      try {
        await axios.post(`${baseUrl}/getFreeExtended`, this.extendData);
      } catch (err) {
        if (err.response) {
          alert(err.response.data.error || "error");
        } else {
          alert(err);
        }
      }
      this.$router.go();
    },
    async getTime() {
      const res = await axios.get(`${baseUrl}/getTime`);
      console.log(">>>>> current unix time");
      this.currTime = res.data.type.unix;
      console.log(this.currTime);
      console.log(moment.unix(this.currTime));
    },
    async getMyCourse() {
      this.isLoading = true;
      try {
        await this.getTime();
        const res = await axios.get(`${baseUrl}/getUserCourse`, {
          params: { user_id: this.$store.getters.getUser.user_id },
        });
        const myCourse = res.data.data;
        for await (const course of myCourse) {
          const dateNow = moment(this.currTime);
          const expireDate = moment(course.course_expire).unix();
          this.myCourseList.push({
            course_id: course.course.course_id,
            courseName: course.course.title,
            courseImg: course.course.cover_img,
            progress: Math.ceil(course.progress),
            is_free_extended: course.course.is_free_extended,
            course_price_id: course.course.course_price_id,
            timeLeft: moment
              .unix(expireDate)
              .diff(moment.unix(dateNow), "days"),
            isExpired: moment.unix(dateNow) > moment.unix(expireDate),
          });
        }
      } catch (err) {
        if (err.response) {
          alert(err.response.data.error || "error");
        } else {
          alert(err);
        }
      }
      this.isLoading = false;
    },
  },
  created() {
    // this.getTime();
    this.getMyCourse();
  },
};
</script>

<style scoped>
.extend-dialog {
  max-width: 300px;
}
.text-line {
  width: 100%;
  max-width: 400px;
  text-align: center;
  border-bottom: 3px solid #0430ad;
  line-height: 0.1em;
  margin: 40px auto;
}

.text-line span {
  background: #fff;
  padding: 0 10px;
}

button.secondary {
  display: block;
  background-color: #07c5f8;
  padding: 10px;
  margin: 20px auto;
  width: 100%;
  color: #fff;
}
</style>
